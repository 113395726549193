import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";

import SEOImage from "../images/seo-images/home-v2.png";

import "../styles/404.scss";
import { Helmet } from "react-helmet";

const NotFound = () => {
  return (
    <Layout>
      <Helmet>
        <title>Page Not Found</title>
        <meta
          name="description"
          content="Whoops! The page you are looking for either doesn't exist or can't be
          found"
        />
        <meta property="og:image" content={SEOImage} />
      </Helmet>

      <section className="nf-hero">
        <div className="nf-hero-grid">
          <StaticImage
            src="../images/404-img.png"
            alt="Cartoon drawing of two little creatures hiding behind bushes"
            placeholder="blurred"
            className="nf-hero-img"
          />
          <div className="nf-hero-content">
            <div className="nf-hero-content-txt">
              <h1>Whoops!</h1>
              <p>
                The page you are looking for either doesn't exist or can't be
                found.
              </p>
            </div>
            <div className="nf-hero-btns">
              <Link to="/" className="primary-btn">
                Return home
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default NotFound;
